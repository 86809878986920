import { SVGProps, Ref, forwardRef } from 'react'

const SvgCaretDown = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path fill="currentColor" fillRule="evenodd" d="M15.5 10 12 14l-3.5-4h7Z" clipRule="evenodd" />
  </svg>
)

const ForwardRef = forwardRef(SvgCaretDown)
export default ForwardRef
